import React from 'react'
import { Container } from '../../components/grid/Container'
import { Separator } from '../../components/Separator'
import { Text } from '../../components/Text'
import { Col } from '../../components/grid/Col'
import { Row } from '../../components/grid/Row'

import FisioterapiaImgUrl from '../../images/tecnicas/fisioterapia.jpg'
import OsteopatiaImgUrl from '../../images/tecnicas/osteopatia.jpg'
import TerapiaFloralImgUrl from '../../images/tecnicas/terapia-floral.jpg'
import { Image } from '../../components/Image'
import { Link } from '../../components/Link'
import { Block } from '../../components/Block'
import { media } from '../../theme/mixins/media'
import styled from 'styled-components/macro'
import { sizes, grid } from '../../theme'
import { rem } from 'polished'
import { imageUrlFor } from '../../lib/image-url'
import { buildImageObj } from '../../lib/helpers'
import { Textos } from '../../pages'
import { PortableText } from '../../components/PortableText'

export interface CategoriaTecnicas {
  fichaDescripcion: string
  fichaImagen: any
  slug: {
    current: string
  }
}

interface Props {
  textos: Textos
  categorias: CategoriaTecnicas[]
}

const NullCategoria = {
  fichaDescripcion: '',
  fichaImagen: null,
  slug: { current: '' },
}

export const TecnicasSection: React.FC<Props> = ({ categorias, textos }) => {
  const fisioterapia =
    categorias.find(c => c.slug.current === 'fisioterapia') || NullCategoria
  const osteopatia =
    categorias.find(c => c.slug.current === 'osteopatia') || NullCategoria
  const bienestar =
    categorias.find(c => c.slug.current === 'bienestar-cuerpo-mente') ||
    NullCategoria

  return (
    <Container
      tablet={{ paddingTop: 'size9' }}
      marginBottom="size5"
      id="tecnicas"
    >
      <Wrapper>
        <Text size="h2" weight="light" uppercase center>
          {textos.tecnicas_titulo}
        </Text>

        <Separator />

        <Row marginBottom="size7">
          <Col tablet={{ start: 3, size: 8 }}>
            <Text as="div" size="large" weight="light" center>
              <PortableText blocks={textos._rawTecnicasSubtitulo} />
            </Text>
          </Col>
        </Row>

        <Row>
          <Tecnica
            imageUrl={
              imageUrlFor(buildImageObj(fisioterapia.fichaImagen))
                .width(420)
                .height(Math.floor((3 / 4) * 420))
                .auto('format')
                .url() || ''
            }
            title="Fisioterapia"
            description={fisioterapia.fichaDescripcion}
            link={{ caption: 'Más sobre fisioterapia', to: '/fisioterapia' }}
          />
          <Tecnica
            imageUrl={
              imageUrlFor(buildImageObj(osteopatia.fichaImagen))
                .width(420)
                .height(Math.floor((3 / 4) * 420))
                .auto('format')
                .url() || ''
            }
            title="Osteopatía"
            description={osteopatia.fichaDescripcion}
            link={{ caption: 'Más sobre osteopatía', to: '/osteopatia' }}
          />
          <Tecnica
            imageUrl={
              imageUrlFor(buildImageObj(bienestar.fichaImagen))
                .width(420)
                .height(Math.floor((3 / 4) * 420))
                .auto('format')
                .url() || ''
            }
            title="Bienestar cuerpo mente"
            description={bienestar.fichaDescripcion}
            link={{
              caption: 'Más sobre bienestar',
              to: '/bienestar-cuerpo-mente',
            }}
          />
        </Row>
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  ${media.onlyMobile`
    padding: ${rem(sizes.size7)} ${rem(grid.default.gap)};
  `}
`

interface TecnicaProps {
  imageUrl: string
  title: string
  description: string
  link: {
    caption: string
    to: string
  }
}
const Tecnica: React.FC<TecnicaProps> = ({
  imageUrl,
  title,
  description,
  link,
}) => (
  <Col tablet={4}>
    <Image src={imageUrl} ratio="4by3" />
    <Text
      size="h3"
      weight="light"
      center
      uppercase
      marginTop="size5"
      marginBottom="size3"
    >
      {title}
    </Text>

    <Text center marginBottom="size5" weight="light">
      {description}
    </Text>

    <Block
      flex
      justify="center"
      marginBottom="size7"
      tablet={{ marginBottom: 'none' }}
    >
      <Link rightArrow to={link.to}>
        {link.caption}
      </Link>
    </Block>
  </Col>
)

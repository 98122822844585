import React from 'react'
import { Layout } from '../containers/Layout'
import { Row } from '../components/grid/Row'
import { Col } from '../components/grid/Col'
import { Container } from '../components/grid/Container'
import { MobileContent } from '../components/grid/MobileContent'
import { TarifaSection } from '../partials/index/TarifaSection'
import { Hero } from '../partials/Hero'
import { Mapa } from '../partials/index/Mapa'
import {
  TecnicasSection,
  CategoriaTecnicas,
} from '../partials/index/TecnicasSection'
import { Block } from '../components/Block'
import { Image } from '../components/Image'
import { Text } from '../components/Text'
import { graphql } from 'gatsby'
import { GraphqlErrorList } from '../components/GraphqlErrorList'
import { mapEdgesToNodes, buildImageObj } from '../lib/helpers'
import { Textos } from '.'
import { PortableText } from '../components/PortableText'
import { HtmlContent } from '../components/HtmlContent/HtmlContent'
import { imageUrlFor } from '../lib/image-url'
import { Seo } from '../partials/Seo'

interface Dolencia {
  nombre: string
  descripcion: string
  imagen: any
}

export const query = graphql`
  query QueTratamosPage {
    sanityTextos {
      _rawQueTratamosSubtitulo
      queTratamos_titulo
      queTratamos_fondo {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
    }

    allSanityDolencia {
      edges {
        node {
          nombre
          descripcion
          imagen {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }

    allSanityCategoriaTecnicas {
      edges {
        node {
          id
          fichaDescripcion
          slug {
            current
          }
          fichaImagen {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }
  }
`

const Page = (props: any) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphqlErrorList errors={errors} />
      </Layout>
    )
  }

  const categoriaTecnicas = mapEdgesToNodes(
    data.allSanityCategoriaTecnicas,
  ) as CategoriaTecnicas[]
  const textos = data.sanityTextos as Textos
  const dolencias = mapEdgesToNodes(data.allSanityDolencia) as Dolencia[]

  return (
    <Layout fullWidth>
      <Seo title="Qué tratamos - Centro Feeling" />
      <Hero
        backgroundUrl={imageUrlFor(buildImageObj(textos.queTratamos_fondo))
          .width(1440)
          .height(Math.floor(600))
          .auto('format')
          .url()}
        title={textos.queTratamos_titulo}
        description={
          <HtmlContent>
            <PortableText blocks={textos._rawQueTratamosSubtitulo} />
          </HtmlContent>
        }
      />

      <Container marginBottom="size7">
        <MobileContent>
          <Row>
            {dolencias.map((dolencia, index) => (
              <Col key={index} mobile={12} tablet={3} desktop={4}>
                <ServiceCard
                  service={{
                    name: dolencia.nombre,
                    imageUrl:
                      imageUrlFor(buildImageObj(dolencia.imagen))
                        .width(400)
                        .height(Math.floor((9 / 16) * 400))
                        .auto('format')
                        .url() || '',
                    description: dolencia.descripcion,
                  }}
                />
              </Col>
            ))}
          </Row>
        </MobileContent>
      </Container>
      <Block marginBottom="size7">
        <TecnicasSection textos={textos} categorias={categoriaTecnicas} />
      </Block>
      <TarifaSection />
      <Mapa />
    </Layout>
  )
}

export interface Service {
  name: string
  imageUrl: string
  description: string
}

export const ServiceCard: React.FC<{ service: Service }> = ({ service }) => (
  <Block marginBottom="size7" tablet={{ marginBottom: 'none' }}>
    <Image ratio="16by9" src={service.imageUrl} marginBottom="size5" />
    <Text as="h2" center marginBottom="size3" uppercase>
      {service.name}
    </Text>
    <Text weight="light" center>
      {service.description}
    </Text>
  </Block>
)

export default Page

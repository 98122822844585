import React from 'react'
import { media } from '../theme/mixins/media'
import { Container } from '../components/grid/Container'
import { Row } from '../components/grid/Row'
import { Col } from '../components/grid/Col'
import { Separator } from '../components/Separator'
import styled, { keyframes } from 'styled-components'
import { sizes } from '../theme'
import { rem } from 'polished'
import { Text } from '../components/Text'

interface Props {
  backgroundUrl: string | null
  title: string
  description: React.ReactNode
}

export const Hero: React.FC<Props> = ({
  backgroundUrl,
  title,
  description,
}) => (
  <HeroWrapper backgroundUrl={backgroundUrl}>
    <Container>
      <Row>
        <Col mobile={12} tablet={{ start: 2, size: 10 }}>
          <Content>
            <Text size="h1" family="merienda" center>
              {title}
            </Text>
            <Separator />
            <Text as="div" size="large" weight="light" center>
              {description}
            </Text>
          </Content>
        </Col>
      </Row>
    </Container>
  </HeroWrapper>
)

const backgroundAnimation = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.5);
  }
`

interface HeroWrapperProps {
  backgroundUrl: string | null
}

const HeroWrapper = styled.div<HeroWrapperProps>`
  position: relative;
  overflow: hidden;
  margin-bottom: ${rem(sizes.size7)};

  ${media.tablet`
    padding: ${rem(sizes.size7)};
    margin-bottom: ${rem(sizes.size7)};

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url(${(p: HeroWrapperProps) => p.backgroundUrl});
      background-size: cover;
      background-position: center bottom;
      animation: ${backgroundAnimation} 70s linear 1 both;
      z-index: -1;
    }
  `}

  ${media.desktop`
    margin-bottom: ${rem(sizes.size9)};
  `}
`

const Content = styled.div`
  padding: ${rem(sizes.size4)};

  background-color: rgba(255, 255, 255, 0.7);

  *:last-child {
    margin-bottom: 0;
  }

  ${media.tablet`
    padding: ${rem(sizes.size7)};
  `}

  ${media.desktop`
    padding: ${rem(sizes.size9)};
    margin-top: ${rem(sizes.size9)};
    margin-bottom: ${rem(sizes.size9)};
  `}
`
